.blog-container {
    background-color: #f0f9e6;
    min-height: 100vh;
    padding: 12px;
  }
  
  .blog-title {
    font-size: 5rem;
    text-align: center;
    font-family: cursive;
  }
  
  .blog-subtitle {
    font-size: 1.5rem;
    color: #666666;
    text-align: center;
    margin-bottom: 12px;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 8px;
  }
  
  .blog-post {
    position: relative;
    height: 300px;
    border-left: 8px solid #66bb6a;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .blog-post-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
  
  .blog-post-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 4px;
    padding-bottom: 4px;
    background-color: rgba(255, 0, 0, 0.75);
    color: #ffffff;
    border-radius: 8px;
  }
  
  .blog-post-title {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 8px;
    background-color: #880e4f;
    color: #ffffff;
  }
  